import Stake from '@/pages/Stake'
import No404 from '@/pages/404'
import Share from '@/pages/Share'
import Team from '@/pages/Team'
import WithDraw from '@/pages/Withdraw'
import Layout from '@/layout'
import Count from '@/pages/Count'
import { Navigate, createHashRouter } from 'react-router-dom'
import type { RouteObject } from 'react-router-dom'

import Home from '@/pages/Home'
import Order from '@/pages/Order'
import OrderDetails from '@/pages/OrderDetails/page'

/**
 * @class 路由定义
 * @param createHashRouter /#/home
 * @param createBrowserRouter /home
 */

const Router: RouteObject[] = [
	{
		path: '/',
		element: <Layout />,
		children: [
			{
				path: '/',
				element: <Navigate to="/home" />,
			},
			{
				path: '/rqudgaby',
				element: <Count />,
			},
			{
				path: '/home',
				element: <Home />,
			},
			{
				path: '/stake',
				element: <Stake />,
			},
			{
				path: '/share',
				element: <Share />,
			},
			{
				path: '/team',
				element: <Team />,
			},
			{
				path: '/withdraw',
				element: <WithDraw />,
			},
			{
				path: '/orders',
				element: <Order />,
			},
			{
				path: '/ordersDetails',
				element: <OrderDetails />,
			},
			{
				path: '/404',
				element: <No404 />,
			},
			{
				path: '*',
				element: <No404 />,
			},
		],
	},
	{
		path: '/404',
		element: <No404 />,
	},
	{
		path: '*',
		element: <No404 />,
	},
]

export default createHashRouter(Router)
