import { Button, Input, Tab, Tabs } from '@nextui-org/react'
import HomeSendReceiveCard from './SendReceiveCard'
import { useEffect, useMemo, useRef, useState } from 'react'
import useAllCoinHooks, { CoinType } from '@/hooks/useAllCoinHooks'
import useSWR from 'swr'
import { serverOrderCreate, serverOrderPrice } from '@/api/order'
import toast from 'react-hot-toast'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useNavigate } from 'react-router-dom'
import Paste from './Paste'
import Close from './Close'
import codeLength from '@/utils/codeLength'
import Difference from './Modal/Difference'

interface IState {
	recv: CoinType | undefined
	send: CoinType | undefined
}

export type IType = 'fixed' | 'float'

const StateInit: IState = {
	recv: undefined,
	send: undefined,
}

export const valueInit = '0'

export default function HomeSwap() {
	const DifferenceRef = useRef<any>(null)

	const { isConnected } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()

	const navigate = useNavigate()

	const { list } = useAllCoinHooks().data

	const [state, setState] = useState<IState>(StateInit)
	const [stateValue, setStateValue] = useState<{
		send: string
		recv: string
	}>({ send: valueInit, recv: valueInit })

	const [type, setType] = useState<IType>('fixed')
	const [isSelectType, setIsSelectType] = useState<'send' | 'recv' | ''>('')

	// 接受地址
	const [recvAddress, setRecvAddress] = useState('')
	// 兑换loading
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		if (list.length > 0) {
			setState({
				recv: list.find(ite => ite.recv === '1' && ite.coin === 'USDT'),
				send: list.find(ite => ite.send === '1'),
			})
			setStateValue({
				...stateValue,
				send: '1',
			})
			setIsSelectType('send')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [list])

	const { data, ...dataPriceArgs } = useSWR(
		isSelectType
			? isSelectType === 'send'
				? [type, stateValue.send, state.send?.code, state.recv?.code]
				: [type, stateValue.recv, state.recv?.code, state.send?.code]
			: null,
		async ([type, value, code, otherCode]) => {
			if (!code || !otherCode) return
			return await serverOrderPrice({
				type: type,
				from: code,
				to: otherCode,
				amount: Number(value),
			}).then(res => {
				return res.data
			})
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	const dataInfo = useMemo(() => {
		if (data) {
			if (isSelectType === 'send') {
				setStateValue({
					send: data?.from.amount,
					recv: data?.to.amount,
				})
				return {
					fromRate: data?.from.rate,
					toRate: data?.to.rate,
				}
			} else {
				setStateValue({
					send: data?.to.amount,
					recv: data?.from.amount,
				})
				return {
					fromRate: data?.to.rate,
					toRate: data?.from.rate,
				}
			}
		}
		return {}
	}, [data, isSelectType])

	// 兑换提交
	const submit = async () => {
		setLoading(true)
		if (!isConnected) {
			toast.error('请先连接钱包')
			open()
			setLoading(false)
			return
		}
		if (!stateValue.send) {
			toast.error('请输入数量')
			setLoading(false)
			return
		}
		if (recvAddress === '') {
			toast.error('Enter your Receive address', { id: 'swapRecvAddressNo' })
			setLoading(false)
			return
		}
		const s = codeLength((state.recv?.code || '') as string)
		console.log('s', s, 'recvAddress.length', recvAddress.length, s !== recvAddress.length)
		if (s !== recvAddress.length) {
			toast.error('Address Error', { id: 'swapRecvAddressNo' })
			setLoading(false)
			return
		}
		try {
			const dataParams: any = await serverOrderCreate({
				type,
				from: state.send?.code || '',
				to: state.recv?.code || '',
				amount: Number(stateValue.send),
				to_address: recvAddress,
			}).finally(() => setLoading(false))
			if (dataParams.code === 0) {
				navigate(`/ordersDetails?id=${dataParams.data.id}`)
			} else {
				toast.error(dataParams.msg || '兑换失败', { id: 'exchangeError' })
			}
		} catch (error) {
			setLoading(false)
		}
	}

	const isOpacitySend = useMemo(() => {
		if (!data) return false
		return data?.from?.max && Number(data?.from?.max) < Number(stateValue.send)
			? true
			: data?.from?.min && Number(data?.from?.min) > Number(stateValue.send)
				? true
				: false
	}, [data, stateValue.send])

	return (
		<>
			<div className="flex w-full flex-col items-center justify-center px-[1.375rem]">
				<div className="tail-text-menu text-2xl font-bold">Fast Coin</div>
				<div className="relative mt-8 flex w-full flex-col gap-4">
					<HomeSendReceiveCard
						isSend
						otherCoin={state.recv?.coin || ''}
						selectInfo={state.send}
						color={state.send?.color || '#f7931a'}
						onSelect={item => setState(prev => ({ ...prev, send: item }))}
						value={stateValue.send}
						onValueChange={value => {
							setStateValue(prev => ({ ...prev, send: value }))
							setIsSelectType('send')
						}}
						otherCode={state.recv?.code || ''}
						type={type}
						otherRate={dataInfo.fromRate}
						isPriceLoading={dataPriceArgs.isLoading || dataPriceArgs.isValidating}
						isOpacity={isOpacitySend}
						isOpacityText={
							data?.from?.max && Number(data?.from?.max) < Number(stateValue.send)
								? `Value Max: ${data?.from.max}`
								: data?.from?.min && Number(data?.from?.min) > Number(stateValue.send)
									? `Value Min: ${data?.from.min}`
									: ''
						}
					/>
					<HomeSendReceiveCard
						otherCoin={state.send?.coin || ''}
						selectInfo={state.recv}
						color={state.recv?.color || '#1BA27A'}
						onSelect={item => setState(prev => ({ ...prev, recv: item }))}
						value={stateValue.recv}
						onValueChange={value => {
							setStateValue(prev => ({ ...prev, recv: value }))
							setIsSelectType('recv')
						}}
						otherCode={state.send?.code || ''}
						type={type}
						otherRate={dataInfo.toRate}
						isPriceLoading={dataPriceArgs.isLoading || dataPriceArgs.isValidating}
					/>
					<svg
						className="absolute right-0 top-[calc(50%_-_0.5625rem)] cursor-pointer"
						width="18"
						height="19"
						viewBox="0 0 18 19"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
						onClick={() => {
							const { send, recv } = state
							if (send && recv) {
								setState({
									send: recv,
									recv: send,
								})
							}
						}}
					>
						<path
							d="M17.2938 5.96846L13.2286 0.0975773C13.2081 0.0673605 13.1805 0.0426544 13.1482 0.025638C13.1159 0.00862164 13.0799 -0.000182037 13.0433 3.43323e-06C12.9688 3.44301e-06 12.8986 0.0372789 12.8581 0.0975774L8.79393 5.96846C8.74789 6.03643 8.7457 6.12195 8.78845 6.19102C8.81023 6.2254 8.84114 6.25305 8.87774 6.27086C8.91433 6.28868 8.95516 6.29595 8.99566 6.29188L11.8845 6.29188L11.8845 11.2605C11.8845 11.2605 11.9503 13.9377 9.90124 14.6153C10.6428 14.544 11.361 14.3176 12.0094 13.9508C12.6578 13.584 13.2218 13.085 13.665 12.4862C14.0222 11.9757 14.2101 11.366 14.2022 10.743L14.2022 6.29517L17.0932 6.29517C17.1339 6.29945 17.1749 6.29228 17.2117 6.27446C17.2486 6.25663 17.2796 6.22887 17.3015 6.1943C17.3228 6.16042 17.3336 6.12095 17.3324 6.08093C17.3313 6.0409 17.3182 6.00213 17.2949 5.96955L17.2938 5.96846Z"
							fill={state.send?.color || '#f7931a'}
						/>
						<path
							d="M0.0386876 12.5992L4.1039 18.47C4.12441 18.5003 4.15204 18.525 4.18435 18.542C4.21666 18.559 4.25267 18.5678 4.28919 18.5676C4.36374 18.5676 4.4339 18.5303 4.47447 18.47L8.53859 12.5992C8.58463 12.5312 8.58682 12.4457 8.54407 12.3766C8.52229 12.3422 8.49138 12.3146 8.45478 12.2968C8.41819 12.2789 8.37735 12.2717 8.33686 12.2757L5.44801 12.2757L5.44801 7.30715C5.44801 7.30715 5.38223 4.6299 7.43128 3.95236C6.68976 4.02367 5.97151 4.25006 5.32311 4.61685C4.67472 4.98363 4.11068 5.48262 3.66756 6.08145C3.31034 6.5919 3.12244 7.20164 3.13036 7.82462L3.13036 12.2725L0.239317 12.2725C0.198645 12.2682 0.157584 12.2753 0.120774 12.2932C0.083964 12.311 0.0528728 12.3388 0.0310132 12.3733C0.00968043 12.4072 -0.00108566 12.4467 8.57729e-05 12.4867C0.00125721 12.5267 0.0143132 12.5655 0.0375913 12.5981L0.0386876 12.5992Z"
							fill={state.recv?.color || '#1BA27A'}
						/>
					</svg>
				</div>
				<div className="mt-7 flex w-full flex-col gap-2">
					<div className="pl-3 text-sm text-[#D9D9D9]">目的地</div>
					<Input
						variant="bordered"
						classNames={{
							input: 'text-xs',
							inputWrapper: 'h-11 rounded-md bg-[#0A0D11] !border-[#0A0D11]',
						}}
						value={recvAddress}
						onValueChange={setRecvAddress}
						endContent={
							<>{recvAddress.length === 0 ? <Paste onChange={setRecvAddress} /> : <Close onClick={() => setRecvAddress('')} />}</>
						}
						placeholder="收款地址"
					/>
				</div>
				<div className="mt-7 flex w-full flex-col gap-2">
					<div className="pl-3 text-sm text-[#D9D9D9]">订单类型</div>
					<Tabs
						classNames={{
							tabList: 'w-full p-0 h-11 rounded-md bg-[#0A0D11] !border-[#0A0D11]',
							tab: 'h-full rounded-md text-xs',
							cursor: 'rounded-md  !bg-transparent border border-[#008FDF]',
							tabContent: 'group-data-[selected=true]:text-[#008FDF] text-[#A6A6A6]',
						}}
						aria-label="Tabs variants"
						selectedKey={type}
						onSelectionChange={key => setType(key as any)}
					>
						<Tab key="fixed" title="固定汇率(1.0%)" />
						<Tab key="float" title="浮动汇率(0.5%)" />
					</Tabs>
				</div>
				<div
					onClick={() => {
						DifferenceRef.current?.onOpen()
					}}
					className="flex w-full cursor-pointer items-center gap-1 py-2"
				>
					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="12" cy="12" r="12" fill="#111326" />
						<path
							d="M11.9864 8.03247C10.5385 8.03247 9.36548 9.20415 9.36548 10.6506C9.36548 10.6903 9.36685 10.7299 9.36821 10.7682L10.2241 10.6206C10.2391 9.66079 11.0225 8.88833 11.9864 8.88833C12.9598 8.88833 13.7487 9.6772 13.7487 10.6493C13.7487 11.6213 12.9598 12.4116 11.9864 12.4116H11.9823L11.9932 13.2688C13.4383 13.2647 14.6073 12.0944 14.6073 10.6506C14.6073 9.20415 13.4342 8.03247 11.9864 8.03247Z"
							fill="white"
						/>
						<path
							d="M9.36548 10.6506C9.36548 10.7685 9.41229 10.8815 9.49562 10.9648C9.57895 11.0482 9.69197 11.095 9.80981 11.095C9.92766 11.095 10.0407 11.0482 10.124 10.9648C10.2073 10.8815 10.2542 10.7685 10.2542 10.6506C10.2542 10.5328 10.2073 10.4198 10.124 10.3364C10.0407 10.2531 9.92766 10.2063 9.80981 10.2063C9.69197 10.2063 9.57895 10.2531 9.49562 10.3364C9.41229 10.4198 9.36548 10.5328 9.36548 10.6506Z"
							fill="white"
						/>
						<path
							d="M11.9946 14.6181C11.7485 14.6181 11.5503 14.4185 11.5503 14.1738V12.8572C11.5503 12.6111 11.7499 12.4128 11.9946 12.4128C12.2407 12.4128 12.439 12.6125 12.439 12.8572V14.1738C12.439 14.4185 12.2394 14.6181 11.9946 14.6181Z"
							fill="white"
						/>
						<path
							d="M11.5571 15.4768C11.5571 15.5947 11.6039 15.7077 11.6873 15.791C11.7706 15.8743 11.8836 15.9211 12.0015 15.9211C12.1193 15.9211 12.2323 15.8743 12.3157 15.791C12.399 15.7077 12.4458 15.5947 12.4458 15.4768C12.4458 15.359 12.399 15.2459 12.3157 15.1626C12.2323 15.0793 12.1193 15.0325 12.0015 15.0325C11.8836 15.0325 11.7706 15.0793 11.6873 15.1626C11.6039 15.2459 11.5571 15.359 11.5571 15.4768Z"
							fill="white"
						/>
					</svg>

					<span className="text-xs text-[#D9D9D9]">有什么区别？</span>
				</div>
				<Button
					isDisabled={isOpacitySend}
					onClick={submit}
					isLoading={loading}
					className="h-10 min-w-[7.625rem] rounded-md bg-[#008FDF] disabled:bg-[#d9d9d9] disabled:text-[#818181] disabled:opacity-100"
				>
					<span className="text-base font-bold">开始交换</span>
				</Button>
			</div>

			<div className="py-3 text-center text-[0.625rem] text-[#D9D9D9]">
				通过使用本网站并创建兑换，您同意Fast Coin Mixer的 <span className="text-[#008FDF]">服务条款</span>和{' '}
				<span className="text-[#008FDF]">隐私政策</span>。
			</div>
			<Difference ref={DifferenceRef} />
		</>
	)
}
