import { ReactComponent as USDTSVG } from '@/assets/svg/token/usdt.svg'
import { Button, Input, Spinner } from '@nextui-org/react'

import { ReactComponent as ADDRESSSVG } from '@/assets/svg/address.svg'
import { ReactComponent as LEVELSVG } from '@/assets/svg/level.svg'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import getChainConfig from '@/lib/web3/getChainConfig'
import useHomeHooks from '@/hooks/useHomeHooks'
import { useSessionStorageState } from 'ahooks'
import { useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { encodeFunctionData, isAddress, zeroAddress } from 'viem'
import { numFormat } from '@/utils'
import toast from 'react-hot-toast'
import { VAULTPROXY } from '@/contract/abi'
import { useTranslation } from 'react-i18next'

import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'

export default function TeamPage() {
	const { t } = useTranslation()

	const { isConnected, address } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()

	const { onSendTransaction } = useSendTransaction()
	const { contracts } = getChainConfig()
	const VaultProxy_ADDRESS = contracts['VaultProxy_ADDRESS']

	const { loading, mutate: HomeMutate } = useHomeHooks()
	const { teamBalance, teamVolume, leavel, isBindUser } = useHomeHooks().data

	const [addressInput, setAddressInput] = useSessionStorageState('curretn-add', { defaultValue: '' })
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [params] = useSearchParams()
	const inviteParams = params.getAll('invite')[0]

	// 是否已绑定
	const isBinds = useMemo(() => {
		return isBindUser !== zeroAddress ? true : false
	}, [isBindUser])

	// 获取url 参数
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const s = useMemo(() => {
		if (isBinds) return false
		if (typeof inviteParams === 'string') {
			setAddressInput(inviteParams)
			return isAddress(inviteParams)
		}
	}, [inviteParams, isBinds, setAddressInput])

	// 是否可以绑定
	const isBindBtn = useMemo(() => {
		if (isBinds) return false
		if (addressInput) return true
		return false
	}, [addressInput, isBinds])

	// useEffect(() => {
	// 	console.log('isBindBtn', isBindBtn)
	// }, [isBindBtn])

	// 团队绑定
	const handleBindTeam = async () => {
		setIsLoading(true)
		if (!isConnected) {
			setIsLoading(false)
			return
		}
		if (addressInput?.toLowerCase() === address?.toLowerCase()) {
			toast.error(t('home.message.tip6'), { id: 'bindErr', duration: 2000 })
			setAddressInput('')
			setIsLoading(false)
			return
		}
		try {
			let abiData = [addressInput]
			const data = encodeFunctionData({
				abi: VAULTPROXY,
				functionName: 'register',
				args: abiData,
			})
			let receipt = await onSendTransaction({
				data: data,
				to: VaultProxy_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			console.log('receipt', receipt)
			toast.success(t('home.message.tip7'), { duration: 2000, id: 'BindSuccess' })
			setAddressInput('')
			setIsLoading(false)
			HomeMutate()
		} catch (error: any) {
			toast.error(error?.message || error, { id: 'bindErr', duration: 2000 })
			setIsLoading(false)
		}
	}

	return (
		<div className="flex w-full flex-col items-center">
			{loading && (
				<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-b-2xl">
					<Spinner label={t('app.loading')} labelColor="warning" color="warning"></Spinner>
				</div>
			)}
			<div className="flex w-full items-center justify-center gap-2 py-4">
				<span className="text-sm font-normal text-white">{t('home.team.title')}</span>
				<USDTSVG className="h-5 w-5" />
			</div>
			<div className="mb-7 w-full text-center text-xl font-bold text-[#F7931A]">{numFormat(teamBalance)}</div>
			{!isBinds && (
				<Input
					placeholder={t('home.team.address.placeholder')}
					classNames={{
						inputWrapper: 'tail-input-wrapper h-10',
						input: 'text-sm text-white',
					}}
					isDisabled={isLoading || isBinds}
					value={addressInput}
					onValueChange={setAddressInput}
				/>
			)}

			{isBinds && (
				<div className="flex w-full flex-col items-start justify-between text-sm text-white">
					<div className="mb-2 flex items-center gap-1">
						<span>{t('home.team.upper')}</span>
					</div>
					<span className="break-all">{isBindUser}</span>
				</div>
			)}
			<div className="flex w-full flex-col gap-4 py-5">
				<div className="flex w-full items-center justify-between">
					<div className="flex items-center gap-1">
						<ADDRESSSVG />
						<span className="text-sm font-normal text-white">{t('home.team.1')}</span>
					</div>
					<span className="text-sm font-bold text-white">{numFormat(teamVolume)}</span>
				</div>
				<div className="flex w-full items-center justify-between">
					<div className="flex items-center gap-1">
						<div className="flex h-[1.375rem] w-[1.375rem] items-center justify-center">
							<LEVELSVG />
						</div>
						<span className="text-sm font-normal text-white">{t('home.team.leavel')}</span>
					</div>
					<span className="text-sm font-bold text-white">{leavel}</span>
				</div>
			</div>

			{isConnected ? (
				<Button
					onClick={() => {
						if (isLoading) return
						if (!isBindBtn) return
						if (!isAddress(addressInput ?? '')) {
							toast.error(t('app.address.error.tips'), { id: 'addressErr', duration: 2000 })
							return
						}
						handleBindTeam()
					}}
					isDisabled={!isBindBtn}
					isLoading={isLoading}
					className={`${isBindBtn ? 'tail-btn-active' : 'tail-btn-no-data'} !w-full`}
				>
					<span>{isBinds ? t('home.team.address.s') : t('home.team.bind')}</span>
				</Button>
			) : (
				<Button onClick={() => open()} className="tail-btn-active mb-5 w-full">
					<span>{t('connect.btn')}</span>
				</Button>
			)}
		</div>
	)
}
