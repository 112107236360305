import { useScroll } from 'ahooks'
import { useEffect, useRef, useState } from 'react'

const useLayoutScrollHooks = () => {
	const ref = useRef<any>(null)
	// 透明度
	const [options, setOptions] = useState<number>(0)
	const scroll = useScroll(typeof window !== 'undefined' ? document : ref)

	// 监听高度设置透明度
	useEffect(() => {
		if (typeof scroll?.top === 'number' && scroll.top > 0) {
			setOptions(scroll.top / 100 > 1 ? 1 : scroll.top / 100)
		} else setOptions(0)
		return () => setOptions(0)
	}, [scroll])

	return { scrollRef: ref, transparency: options }
}

export default useLayoutScrollHooks
