import { serverOrderList } from '@/api/order'
import useWalletStore from '@/lib/store/useWalletStore'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'

/**
 *
 * @returns
 */
export type OrderInfoType = {
	id: string
	orderid: string
	createTime: string
	send: {
		image: string
		amount: string
		symbol: string
	}
	end: {
		image: string
		amount: string
		symbol: string
	}
	receiveAddress: string
}

export default function useOrderHooks() {
	const { isConnected, address } = useWeb3ModalAccount()
	const isSign = useWalletStore(state => state.isSign)

	const { data, ...args } = useSWR(
		isConnected && !isSign && address ? ['useOrderHooks', address] : null,
		async ([, address]) => {
			let list: OrderInfoType[] = []
			const dataParams: any = await serverOrderList({ page: 1, limit: 20 })
			if (dataParams.code === 0) {
				console.log('dataParams.data', dataParams.data)
				list = dataParams.data.list.map((item: any) => {
					return {
						id: item.id,
						orderid: item.order_sn,
						createTime: item.created_at,
						send: {
							image: item.ccies_from_code.logo,
							amount: Number(item.send_amount),
							symbol: item.from_code,
						},
						end: {
							image: item.ccies_to_code.logo,
							amount: Number(item.to_amount),
							symbol: item.to_code,
						},
						receiveAddress: item.to_address,
					}
				})
			}
			return {
				list,
			}
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return {
		data: {
			orderList: data?.list || ([] as OrderInfoType[]),
		},
		...args,
	}
}
