import { Button } from '@nextui-org/react'

import { formatStrAddress } from '@/utils'

import BaseModal from '@/components/Modal'
import { useRef } from 'react'
import useWalletStore from '@/lib/store/useWalletStore'
import { useTranslation } from 'react-i18next'

import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { removeToken } from '@/utils/local'

export default function ConnectModale({ isNoPage = false }: { isNoPage?: boolean }) {
	const { t } = useTranslation()
	const { isConnected, address } = useWeb3ModalAccount()
	const { disconnect } = useDisconnect()
	const { open } = useWeb3Modal()

	const BaseModalRef = useRef<any>(null)
	const isSign = useWalletStore(store => store.isSign)

	if (isNoPage) return <></>

	return (
		<>
			<div className="flex w-full items-center justify-center">
				{!isConnected && (
					<Button onClick={() => open()} className="relative z-[99] h-[1.875rem] min-w-[6.25rem] bg-[#353A60]" radius="full">
						<span className="text-sm font-normal text-white">{t('connect.btn')}</span>
					</Button>
				)}
				{isConnected && (
					<Button
						onClick={() => {
							BaseModalRef.current?.onOpen()
						}}
						className="relative z-[99] h-[1.875rem] min-w-[6.25rem] bg-[#353A60]"
						radius="full"
					>
						{isSign ? (
							<span className="text-sm font-normal text-[var(--color-1)]">{t('connect.sign')}</span>
						) : (
							<span className="text-sm font-normal text-white">{formatStrAddress(4, 4, address ?? '')}</span>
						)}
					</Button>
				)}
			</div>
			<BaseModal ref={BaseModalRef} bodyClassName="min-h-[8.75rem] px-10 py-12 justify-center items-center">
				<Button
					onClick={() => {
						disconnect()
						BaseModalRef.current?.onClose()
						removeToken()
					}}
					className="tail-btn-one"
				>
					<span className="text-base font-bold text-white">{t('connect.logout')}</span>
				</Button>
			</BaseModal>
		</>
	)
}
