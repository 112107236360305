import { useDisconnect, useWeb3ModalAccount, useWeb3ModalProvider } from '@web3modal/ethers/react'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import useWalletStore from '@/lib/store/useWalletStore'

import { verifyMessage } from '@/utils'
import { ethers } from 'ethers'
import { useTranslation } from 'react-i18next'
import { serverGetNonce, serverLogin } from '@/api/login'
import { getToken, setToken } from '@/utils/local'

export default function useSign() {
	const { t } = useTranslation()
	const { walletProvider } = useWeb3ModalProvider()
	const { disconnect } = useDisconnect()
	const { isConnected, address } = useWeb3ModalAccount()

	useEffect(() => {
		if (isConnected) {
			handleSign()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConnected, address])

	const handleSign = async () => {
		const uid = await serverGetNonce({ address: address || '' }).then(res => {
			console.log(res)
			return res?.data?.nonce
		})
		if (!uid) return
		const isSignature = getToken() || ''
		if (isSignature) return
		useWalletStore.getState().setIsSign(true)
		if (!address || !walletProvider) {
			useWalletStore.getState().setIsSign(false)
			return
		}
		try {
			const web3 = new ethers.BrowserProvider(walletProvider)
			const signer = await web3.getSigner()
			const signature = await signer?.signMessage(uid.toString())
			const isTrue = await verifyMessage(uid, address, signature)
			if (!isTrue) {
				useWalletStore.getState().setIsSign(false)
				disconnect()
				toast.error(t('connect.sign.error'))
			} else {
				const isTrue = await onLogin(signature)
				if (isTrue) useWalletStore.getState().setIsSign(false)
				else {
					useWalletStore.getState().setIsSign(false)
					disconnect()
					toast.error(t('connect.sign.error'))
				}
			}
		} catch (error: any) {
			console.log('error', error)
			toast.error(t('connect.sign.error'))
			disconnect()
			useWalletStore.getState().setIsSign(false)
		}
	}

	const onLogin = async (sign: string): Promise<boolean> => {
		try {
			const token = await serverLogin({ address: address || '', sign }).then(res => {
				return res?.data?.token
			})
			if (token) {
				setToken(token)
				return true
			} else return false
		} catch (error) {
			return false
		}
	}
}
