import request from '@/utils/requestApi'

export const serverGetNonce = async (body: { address: string }) =>
	request({
		url: '/get_nonce',
		method: 'post',
		data: body,
	})

export const serverLogin = async (body: { address: string; sign: string }) =>
	request({
		url: '/login',
		method: 'post',
		data: body,
	})
