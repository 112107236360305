import BaseModal from '@/components/Modal'
import { useRef, useState } from 'react'

import { ReactComponent as MENU } from '@/assets/svg/menu.svg'
import { ReactComponent as LOGO_MAX } from '@/assets/svg/logo-max.svg'

import { ReactComponent as ICON1 } from '@/assets/svg/icon/icon1.svg'
// import { ReactComponent as ICON2 } from '@/assets/svg/icon/icon2.svg'
// import { ReactComponent as ICON3 } from '@/assets/svg/icon/icon3.svg'
// import { ReactComponent as ICON4 } from '@/assets/svg/icon/icon4.svg'
// import { ReactComponent as ICON5 } from '@/assets/svg/icon/icon5.svg'
// import { ReactComponent as ICON6 } from '@/assets/svg/icon/icon6.svg'
// import { ReactComponent as ICON7 } from '@/assets/svg/icon/icon7.svg'

import { ReactComponent as ICON8 } from '@/assets/svg/icon/icon_8.svg'
import { ReactComponent as ICON9 } from '@/assets/svg/icon/icon_9.svg'
import { ReactComponent as ABOUT } from '@/assets/svg/icon/about.svg'
import { ReactComponent as HELP } from '@/assets/svg/icon/help.svg'
import { ReactComponent as ICON10 } from '@/assets/svg/icon/icon_10.svg'
import { ReactComponent as ICON11 } from '@/assets/svg/icon/icon_11.svg'

import { ReactComponent as TIPSSVG } from '@/assets/svg/tips.svg'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

// import { ReactComponent as COPYSVG } from '@/assets/svg/copy.svg'
// import { Button } from '@nextui-org/react'
// import copy from 'copy-to-clipboard'
import { useTranslation } from 'react-i18next'
import ConnectModale from '@/components/Connect'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const List: {
	icon: any
	label: string
	uri: string
	target?: '_blank'
}[] = [
	{ icon: <ICON1 className="shrink-0" />, label: 'menu.home', uri: '/home' },
	{ icon: <ICON9 className="shrink-0" />, label: 'My Orders', uri: '/orders' },
	{ icon: <ICON8 className="shrink-0" />, label: 'Stake', uri: '/stake' },
	{ icon: <ABOUT className="shrink-0" />, label: 'About', uri: '/about' },
	{ icon: <HELP className="shrink-0" />, label: '常见问题', uri: '/help' },
	{ icon: <ICON10 className="shrink-0" />, label: '服务条款', uri: '/help' },
	{ icon: <ICON11 className="shrink-0" />, label: '隐私条款', uri: '/help' },

	// {
	// 	icon: <ICON2 className="shrink-0" />,
	// 	label: 'menu.contract',
	// 	uri: 'https://github.com/aibot-finance/pool',
	// 	target: '_blank',
	// },
	// { icon: <ICON4 className="shrink-0" />, label: 'menu.option.contract', uri: '' },
	// { icon: <ICON5 className="shrink-0" />, label: 'menu.option.ai', uri: '' },
	// { icon: <ICON6 className="shrink-0" />, label: 'menu.option.qr', uri: '' },
	// { icon: <ICON7 className="shrink-0" />, label: 'menu.option.ai.mall', uri: '' },
]

export default function MenuModule() {
	const { t } = useTranslation()
	const { isConnected } = useWeb3ModalAccount()

	const BaseModalRef = useRef<any>(null)
	// const CopyRef = useRef<any>(null)

	// 需要复制的文案
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [copyText, setCopyText] = useState<string>('')

	const navigate = useNavigate()

	return (
		<div className="">
			<MENU
				className="cursor-pointer"
				onClick={() => {
					BaseModalRef.current?.onOpen()
				}}
			/>
			<BaseModal
				ref={BaseModalRef}
				placement="top"
				baseClassName="!w-[16.875rem] !my-0 sm:!my-8 !mr-[calc(100%_-_16.875rem)] !ml-0 sm:!mr-[7.125rem] h-screen sm:h-[calc(100vh_-_4rem)] !rounded-none sm:!rounded-l-2xl"
				bodyClassName="py-11 px-[1.125rem] gap-5"
			>
				<LOGO_MAX className="flex w-full items-center justify-center" />
				<ConnectModale />
				<div className="flex flex-col gap-5">
					{List.filter(item => {
						if (!isConnected) return item.uri !== '/orders'
						return true
					}).map((item, key) => (
						<div
							onClick={() => {
								if (item.uri === '') {
									toast('Coming Soon', {
										icon: <TIPSSVG className="h-4 w-4" />,
									})
								} else if (item.target) {
									window.open(item.uri)
								} else {
									navigate(item.uri, {
										replace: true,
									})
									BaseModalRef.current?.onClose()
								}
							}}
							className="group flex cursor-pointer items-center gap-3"
							key={key}
						>
							{item.icon}
							<span className="line-clamp-1 w-full group-hover:text-[var(--theme)]">{t(item.label)}</span>
						</div>
					))}
				</div>
			</BaseModal>
			{/* <BaseModal placement="center" ref={CopyRef} bodyClassName="p-0" centerBaseClass="sm:w-[20.3125rem] max-w-[20.3125rem]">
				<div className="flex flex-col">
					<div className="flex h-14 items-center justify-center gap-1 border-b border-[#2D931E]">
						<COPYSVG />
						<span className="text-base text-white">Copy</span>
					</div>
					<div className="flex w-full flex-col items-center py-8 pb-6">
						<span className="mb-8 text-sm text-white">{copyText}</span>
						<Button
							onClick={() => {
								copy(copyText)
								toast.success('Copy successful')
							}}
							className="tail-btn-active min-w-[7.8125rem]"
						>
							<span>Copy</span>
						</Button>
					</div>
				</div>
			</BaseModal> */}
		</div>
	)
}
