import { serverOrderQrcode } from '@/api/order'
import { cnTw } from '@/utils'
import { Spinner } from '@nextui-org/react'
import { useState } from 'react'
import useSWR from 'swr'

export default function OrderDetailsQrCode({ id }: { id: string }) {
	const [select, setSelect] = useState<'Address' | 'With Amount'>('Address')

	const { data: qrCodeData, ...args } = useSWR(
		id ? ['OrderDetailsQrCode', id] : null,
		async ([, id]) => {
			return await serverOrderQrcode({ id }).then((res: any) => {
				if (res.code === 0)
					return res.data as {
						title: string
						src: string
						checked?: boolean
					}[]
				return []
			})
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	const loading = args.isValidating || args.isLoading

	const image =
		select === 'Address'
			? qrCodeData?.find(ite => ite.title === 'Address')?.src
			: qrCodeData?.find(ite => ite.title !== 'Address')?.src

	return (
		<div className="flex h-full w-full flex-col rounded-md bg-white text-black">
			<div className="flex h-[calc(230px_-_40px)] items-center justify-center p-2">
				<div className="flex items-center justify-center px-2">
					{loading ? <Spinner color="primary" size="sm" /> : <img src={image} alt="" />}
				</div>
			</div>
			<div className="flex h-10 shrink-0 items-center justify-center">
				<div
					className={cnTw(
						'flex h-full w-full cursor-pointer items-center justify-center rounded-bl-md rounded-tr-md bg-[#ECECEC] text-sm text-[#333] hover:bg-[#ECECEC] hover:text-[#333]',
						select === 'Address' && '!bg-transparent text-[#999999]',
					)}
					onClick={() => setSelect('Address')}
				>
					Address
				</div>
				<div
					className={cnTw(
						'flex h-full w-full cursor-pointer items-center justify-center rounded-br-md rounded-tl-md bg-[#ECECEC] text-sm text-[#333] hover:bg-[#ECECEC] hover:text-[#333]',
						select !== 'Address' && '!bg-transparent text-[#999999]',
					)}
					onClick={() => setSelect('With Amount')}
				>
					With amount
				</div>
			</div>
		</div>
	)
}
