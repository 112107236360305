import useWalletStore from '@/lib/store/useWalletStore'
import LangModule from '../Lang'
import MenuModule from '../Menu'

import { ReactComponent as LOGO } from '@/assets/svg/logo-max.svg'
import useLayoutScrollHooks from '@/hooks/useLayoutScrollHooks'
import { cnTw } from '@/utils'

export default function TopBarModule() {
	const isSign = useWalletStore(state => state.isSign)
	const { transparency } = useLayoutScrollHooks()
	return (
		<div
			className={cnTw(
				'fixed flex h-14 w-full items-center justify-between border-b border-[#454545] px-3 py-4',
				!isSign && 'z-50',
			)}
			style={{
				background: `rgba(12,13,22, ${transparency})`,
			}}
		>
			<MenuModule />
			<LOGO />
			<LangModule />
		</div>
	)
}
