import { Spinner } from '@nextui-org/react'
import useHomeTrade from '../hooks/useHomeTrade'

export default function HomeTrade() {
	const { data: tradeData, ...args } = useHomeTrade()

	const loading = args.isLoading || args.isValidating

	return (
		<div className="my-8 px-2">
			<div className="tail-text-menu w-full text-center text-2xl font-bold">最近的交易</div>
			{loading ? (
				<div className="flex min-h-[200px] items-center justify-center">
					<Spinner color="white" size="sm" />
				</div>
			) : (
				<div className="flex flex-col gap-4 pt-5">
					{tradeData.orderList.map((item, index) => (
						<div key={index} className="rounded-[0.625rem] bg-[#21284B]">
							{/* <div className="flex items-center justify-center gap-2 py-4">1123123</div>
							<div className="h-[1px] w-full bg-[#4D536F]"></div> */}
							<div className="flex items-center justify-center gap-2 py-4">
								<div className="flex items-center gap-2 text-xs text-white">
									{Number(item.send_amount)} {item.from_code}
									<img src={item.ccies_from_code.logo} className="inline-block h-4 w-4" alt="" />
								</div>
								<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M0.44873 5.75732H7.72973V6.43149H0.44873V5.75732Z" fill="white" />
									<path d="M7.68872 3.73987L11.6092 6.0424L7.68872 8.40716V3.73987Z" fill="white" />
								</svg>
								<div className="flex items-center gap-2 text-xs text-white">
									{item.to_code}
									<img src={item.ccies_to_code.logo} className="inline-block h-4 w-4" alt="" />
								</div>
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	)
}
