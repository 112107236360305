import moment from 'moment'
import useOrderHooks from './hooks/useOrderHooks'
import { formatStrAddress2 } from '@/utils'
import { Link } from 'react-router-dom'
import { Spinner, Table, TableBody, TableCell, TableColumn, TableHeader, TableRow, getKeyValue } from '@nextui-org/react'

const columns = [
	{
		key: 'createTime',
		label: '日期 OROER ID',
	},
	{
		key: 'send',
		label: '发送',
	},
	{
		key: 'end',
		label: '接收',
	},
	{
		key: 'receiveAddress',
		label: '接收',
	},
]

export default function Order() {
	const { data: OrderData, ...OrderArgs } = useOrderHooks()

	const loading = OrderArgs.isLoading || OrderArgs.isValidating

	return (
		<div className="pt-[1.625rem]">
			<div className="tail-text-menu pl-2 text-2xl font-bold">已完成订单</div>
			<Table
				selectionMode="single"
				color="default"
				classNames={{
					wrapper: 'shadow-none bg-transparent border-transparent px-0',
					th: 'bg-transparent text-[#A6A6A6] font-bold text-[10px] h-[28px]',
					td: 'before:bg-[#151A30] before:!opacity-100 group-aria-[selected=false]:group-data-[hover=true]:before:bg-[#1F2849]',
				}}
				aria-label="big node"
			>
				<TableHeader columns={columns}>{column => <TableColumn key={column.key}>{column.label}</TableColumn>}</TableHeader>
				<TableBody items={OrderData.orderList}>
					{item => (
						<TableRow key={item.id}>
							{columnKey => {
								if (columnKey === 'createTime')
									return (
										<TableCell>
											<div className="pr-2">
												{moment(item.createTime).format('MM/DD/YYYY')} <br />
												<Link to={`/ordersDetails?id=${item.id}`} className="text-[#45648B] hover:text-primary-300">
													{item.orderid}
												</Link>
											</div>
										</TableCell>
									)
								if (columnKey === 'send')
									return (
										<TableCell>
											<div className="flex items-center gap-1 whitespace-nowrap px-2">
												<img src={item.send.image} className="h-3 w-3 rounded-full" alt="" />
												<span>{item.send.amount}</span>
												<span>{item.send.symbol}</span>
											</div>
										</TableCell>
									)
								if (columnKey === 'end')
									return (
										<TableCell>
											<div className="flex items-center gap-1 whitespace-nowrap px-2">
												<img src={item.end.image} className="h-3 w-3 rounded-full" alt="" />
												<span>{item.end.amount}</span>
												<span>{item.end.symbol}</span>
											</div>
										</TableCell>
									)
								if (columnKey === 'receiveAddress')
									return (
										<TableCell>
											<div className="whitespace-nowrap pl-2">{formatStrAddress2(10, 10, item.receiveAddress)}</div>
										</TableCell>
									)
								return <TableCell>{getKeyValue(item, columnKey)}</TableCell>
							}}
						</TableRow>
					)}
				</TableBody>
			</Table>
			{loading && (
				<div className="flex h-[12.5rem] items-center justify-center">
					<Spinner size="sm" color="white" />
				</div>
			)}
		</div>
	)
}
