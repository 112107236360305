export const codes = [
	'BTC',
	'ETH',
	'USDT',
	'BSC',
	'USDTTRC',
	'XMR',
	'SHIBBSC',
	'USDCARBITRUM',
	'TWTBSC',
	'TUSD',
	'TRX',
	'TON',
	'WSOL',
	'SOL',
	'USDCMATIC',
	'SHIB',
	'POLETH',
	'POL',
	'PAXGETH',
	'MKR',
	'MANAETH',
	'USDCBSC',
	'USDCETH',
	'LINK',
	'USDCSOL',
	'USDP',
	'USDTARBITRUM',
	'USDTBSC',
	'USDTMATIC',
	'USDTSOL',
	'VET',
	'XLM',
	'XRP',
	'XTZ',
	'ZEC',
	'ZRX',
	'DAIMATIC',
	'ADABSC',
	'APT',
	'ATOM',
	'AVAX',
	'BAT',
	'BCH',
	'BNBOPBNB',
	'WBNBBSC',
	'WBNBOPBNB',
	'BTCBSC',
	'BTCLN',
	'BTT',
	'CAKE',
	'DAIBSC',
	'DAIETH',
	'LTC',
	'DASH',
	'DOGE',
	'DOT',
	'EOS',
	'ETC',
	'ETHARBITRUM',
	'ETHBASE',
	'ETHBSC',
	'ETHOP',
	'ETHZKSYNC',
	'WETHARBITRUM',
	'WETHETH',
	'FTM',
	'KCS',
	'AAVEETH',
]

export const codesLength: {
	[str in string]: number
} = {
	BTC: 34,
	ETH: 42,
	USDT: 42,
	BSC: 42,
	USDTTRC: 34,
	XMR: 95,
	SHIBBSC: 42,
	USDCARBITRUM: 42,
	TWTBSC: 42,
	TUSD: 42,
	TRX: 34,
	TON: 48,
	WSOL: 32,
	SOL: 32,
	USDCMATIC: 42,
	SHIB: 42,
	POLETH: 42,
	POL: 42,
	PAXGETH: 42,
	MKR: 42,
	MANAETH: 42,
	USDCBSC: 42,
	USDCETH: 42,
	LINK: 42,
	USDCSOL: 32,
	USDP: 42,
	USDTARBITRUM: 42,
	USDTBSC: 42,
	USDTMATIC: 42,
	USDTSOL: 32,
	VET: 42,
	XLM: 56,
	XRP: 34,
	XTZ: 36,
	ZEC: 95,
	ZRX: 42,
	DAIMATIC: 42,
	ADABSC: 42,
	APT: 32,
	ATOM: 44,
	AVAX: 42,
	BAT: 42,
	BCH: 42,
	BNBOPBNB: 42,
	WBNBBSC: 42,
	WBNBOPBNB: 42,
	BTCBSC: 42,
	BTCLN: 42,
	BTT: 42,
	CAKE: 42,
	DAIBSC: 42,
	DAIETH: 42,
	LTC: 34,
	DASH: 34,
	DOGE: 34,
	DOT: 42,
	EOS: 12,
	ETC: 42,
	ETHARBITRUM: 42,
	ETHBASE: 42,
	ETHBSC: 42,
	ETHOP: 42,
	ETHZKSYNC: 42,
	WETHARBITRUM: 42,
	WETHETH: 42,
	FTM: 42,
	KCS: 42,
	AAVEETH: 42,
}

const codeLength = (code: string) => {
	if (codesLength[code]) {
		return codesLength[code]
	}
	return 42
}

export default codeLength
