import axios from 'axios'
import { baseURL } from '@/utils'
import toast from 'react-hot-toast'
import { getToken } from './local'

/** Create Axios instance */
const service = axios.create({
	timeout: 180000,
	baseURL,
	headers: {
		'Content-Type': 'application/json;charset=utf-8',
	},
})

/** request Interceptor */
service.interceptors.request.use(
	config => {
		if (config.headers) {
			config.headers['token'] = `${getToken()}`
		}
		return config
	},
	(error: any) => {
		Promise.reject(error)
	},
)

/** Response interceptor */
service.interceptors.response.use(
	res => {
		return res.data
	},
	error => {
		toast.error(`${error.message}.Please refresh the page and try again`, { id: 'axios-error', duration: 2000 })
		return Promise.reject(error)
	},
)

export default service
