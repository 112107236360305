import { serverOrderAllCoin } from '@/api/order'
import useSWR from 'swr'

export type CoinType = {
	id: number
	code: string
	coin: string
	network: string
	name: string
	recv: string
	send: string
	tag: string
	logo: string
	color: string
	priority: string
	status: number
	updated_at: string
}

export default function useAllCoinHooks() {
	const { data, ...args } = useSWR(
		'useAllCoinHooks',
		async () => {
			return await serverOrderAllCoin()
				.then((res: any) => {
					if (res.code === 0 && res.data) return res.data
					return []
				})
				.then(res => {
					console.log('res', res)
					const data = res.map((item: any) => {
						return item.code
					})
					console.log('data', JSON.stringify(data))
					return {
						list: res,
					}
				})
		},
		{
			revalidateIfStale: false,
			revalidateOnFocus: false,
		},
	)

	return {
		data: {
			list: (data?.list || []) as CoinType[],
		},
		...args,
	}
}
